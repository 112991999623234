import { useState, useEffect, useRef } from "react";
import { isUserLoggedIn } from "../../utils/auth";
import Api from "../../services/Api";
import { scannerValidateInputBox } from "../../utils/productGrid";
import useCartOperations from "../cart/useCartOperations";
import { useForm } from "react-hook-form";
import useToastify from "../ui/useToastify";
import { navigate } from "gatsby";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useSWRConfig } from "swr";
import { cartToCartFormData } from "../../utils/cart";
import {
  cartFormItemsURL,
  customerOrdersURL,
  orderProductsURL,
  sampleCsvURL,
} from "../../ApiEndpoints";
import { getCurrentDate } from "../../utils/date";
import useCart from "../cart/useCart";
import { isBrowser } from "../../utils/common";

const allowedExtensions = ["xlsx", "xls"];

function useCartForm(skus, setSKUs) {
  const user = isUserLoggedIn();
  const { addToCart, clearCart } = useCartOperations();
  const { register, watch, reset } = useForm();
  const { lineItems } = useCart();
  const { mutate } = useSWRConfig();
  // const allItems = lineItems.map((lineItem) => ({
  //   sku: lineItem.sku,
  //   quantity: lineItem.quantity,
  //   extended_list_price: lineItem.extended_list_price,
  //   price: lineItem.sale_price,
  // }));

  const existingItems = lineItems;
  // console.log(allItems, "eti");

  const [cartResponse, setCartResponse] = useState();
  const { toastMessage } = useToastify();
  const aRef = useRef(null);
  const [state, setState] = useState({
    lineItems: [],
    file: "",
    fileError: "",
    cartTotal: "",
    error: "",
    inputFiledValue: "",
    inputFieldError: "",
    missing_skus_string: "",
    isDuplicateUpc: false,
    isUsingOrderId: false,
    duplicateUpcItems: [],
    orderIds: "",
    loadingCart: true,
  });
  const [isLoadingCart, setIsLoadingCart] = useState(true);
  const [isLoading, setLoading] = useState();
  const [isUsingInput, setUsingInput] = useState();

  useEffect(async () => {
    loadCartData();
  }, []);

  useEffect(() => {
    if (existingItems.length > 0 && state.lineItems.length === 0) {
      loadCartData();
    }
  }, [existingItems]);

  // console.log(lineItems, "lineItems");
  function calculateSum(items) {
    let sum = 0;

    if (items.length) {
      items.forEach((i) => {
        // sum += i["extended_price"] || i["extended_list_price"];
        sum += i["extended_price"];
      });
    }

    if (sum) {
      window.localStorage.setItem("cartTotal", sum);
    }

    return sum;
  }

  function checkForItemError(items) {
    // const itemErrorFlag = window.localStorage.getItem('itemError')
    window.localStorage.setItem("itemError", 0);
    window.localStorage.setItem("itemErrorString", "");
    setState((prevState) => ({
      ...prevState,
      error: "",
    }));
    if (items.length) {
      items.forEach((i) => {
        if (
          i["inventory_level"] === 0 ||
          i["inventory_level"] < i["quantity"] ||
          i["minPurchaseQuantity"] !== null ||
          i["maxPurchaseQuantity"] !== null
        ) {
          if (i["inventory_level"] === 0) {
            window.localStorage.setItem("itemError", 1);
            setState((prevState) => ({
              ...prevState,
              error:
                "Some product in your form has zero inventory. Please remove that from cart.",
            }));
            window.localStorage.setItem(
              "itemErrorString",
              "Some product in your form has zero inventory. Please remove that from cart."
            );
          } else if (
            i["quantity"] < i["minPurchaseQuantity"] &&
            i["minPurchaseQuantity"] !== null
          ) {
            window.localStorage.setItem("itemError", 1);
            setState((prevState) => ({
              ...prevState,
              error:
                "Minimum purchase quantity is more than current quantity for some products.",
            }));
            window.localStorage.setItem(
              "itemErrorString",
              "Minimum purchase quantity is more than current quantity for some products."
            );
          } else if (
            i["quantity"] > i["maxPurchaseQuantity"] &&
            i["maxPurchaseQuantity"] !== null
          ) {
            window.localStorage.setItem("itemError", 1);
            setState((prevState) => ({
              ...prevState,
              error:
                "Quantity is more than maximum purchase quantity for some products.",
            }));
            window.localStorage.setItem(
              "itemErrorString",
              "Quantity is more than maximum purchase quantity for some products."
            );
          } else if (i["inventory_level"] < i["quantity"]) {
            window.localStorage.setItem("itemError", 1);
            setState((prevState) => ({
              ...prevState,
              error:
                "Inventory is less than quantity for some products in your cart please fix them first.",
            }));
            window.localStorage.setItem(
              "itemErrorString",
              "Inventory is less than quantity for some products in your cart please fix them first."
            );
          }
        }
      });
    }
  }

  function loadCartData() {
    setIsLoadingCart(true);
    let res = JSON.parse(window.localStorage.getItem("cartFormItems")) || [];

    if (res.length || existingItems.length > 0) {
      const existingRes = cartToCartFormData(existingItems);

      // Find and add remaining items
      const remainingItems = findRemainingItems(res, existingRes);
      res = res.concat(remainingItems);

      // Save updated cart items to local storage
      window.localStorage.setItem("cartFormItems", JSON.stringify(res));

      // Update the state with the new items
      setState((prevState) => ({
        ...prevState,
        lineItems: existingRes,
        cartTotal: calculateSum(existingRes),
      }));

      checkForItemError(res);
    } else {
      // If no items in local storage or existingItems, reset the state
      setState((prevState) => ({
        ...prevState,
        lineItems: [],
        cartTotal: "",
        missing_skus_string: "",
      }));
      window.localStorage.setItem("cartTotal", "");
      window.localStorage.setItem("itemErrorString", "");
      window.localStorage.setItem("itemError", 0);
      window.localStorage.setItem("cartFormItems", JSON.stringify([]));
      window.localStorage.setItem("duplicateUpcItems", JSON.stringify([]));
      reset();
    }

    setIsLoadingCart(false);
  }

  // Helper function to find remaining items
  const findRemainingItems = (res, existingRes) => {
    const resIds = res.map((item) => item.product_id); // Assuming each item has a unique 'product_id'
    return existingRes.filter((item) => !resIds.includes(item.product_id));
  };

  function findSkuInLocal(sku) {
    let res = JSON.parse(window.localStorage.getItem("cartFormItems"));
    let isFound = false;
    if (res && res.length > 0) {
      for (const i of res) {
        if (i["sku"] === sku) {
          i["quantity"] = i["quantity"] + 1;
          i["extended_price"] =
            i["quantity"] * (i["price"] || i["calculated_price"]);

          isFound = true;
          break;
        }
      }
      if (isFound) {
        setState((prevState) => ({
          ...prevState,
          lineItems: res,
          cartTotal: calculateSum(res),
        }));
        window.localStorage.setItem("cartFormItems", JSON.stringify(res));
        setState((prevState) => ({
          ...prevState,
          inputFieldError: "",
        }));
        resetInputField();
        toastMessage("success", "Product added successully.");
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const handleInputTypeChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      isUsingOrderId: value,
      inputFieldError: "",
      orderIds: "",
    }));
    if (value) {
      getCustomerOrders();
    }
    resetInputField();
  };

  async function getSingleProduct(sku) {
    // setLoading(true);
    const foundLocally = findSkuInLocal(sku);
    if (!foundLocally) {
      setUsingInput(true);
      const getSingleKey = () => (sku ? `/products?sku=${sku}` : null);
      const response = await Api.get(getSingleKey());
      if (response.status === 200) {
        let tempSku = [];
        const res = response?.data?.data;
        if (res?.length > 0) {
          if (res?.length === 1) {
            for (const r of res) {
              if (r.product_id !== 0 && r.id !== 0) {
                tempSku.push({
                  sku: r.sku,
                  quantity: parseInt(1),
                });

                setState((prevState) => ({
                  ...prevState,
                  inputFieldError: "",
                }));
                const timeOutId = setTimeout(() => setSKUs(tempSku), 1000);
                resetInputField();
                return () => clearTimeout(timeOutId);
              }
            }
          } else if (res?.length > 1) {
            setState((prevState) => ({
              ...prevState,
              isDuplicateUpc: true,
            }));
            // setState((prevState) => ({
            //   ...prevState,
            //   duplicateUpcItems: res,
            // }));
            for (const r of res) {
              if (r.sku !== "") {
                tempSku.push({
                  sku: r.sku,
                  quantity: parseInt(1),
                });
              }
            }
            const timeOutId = setTimeout(() => setSKUs(tempSku), 1000);
            resetInputField();

            // window.localStorage.setItem(
            //   "duplicateUpcItems",
            //   JSON.stringify(res)
            // );
            return () => clearTimeout(timeOutId);
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            inputFieldError: "Please enter a valid code",
          }));
        }
      } else {
        setState((prevState) => ({
          ...prevState,
          inputFieldError: "Please enter a valid code",
        }));
      }
    }
    setLoading(false);
  }

  async function getProductUsingOrderId(order_id) {
    // setLoading(true);

    setUsingInput(true);
    const getSingleKey = () =>
      order_id ? `${orderProductsURL}?order_id=${order_id}` : null;
    const response = await Api.get(getSingleKey());

    if (response.status === 200) {
      let tempSku = [];
      const res = response?.data?.data;
      if (res?.length > 0) {
        for (const r of res) {
          if (r.product_id !== 0 && r.variant_id !== 0) {
            tempSku.push({
              sku: r.sku,
              quantity: parseInt(r.quantity),
            });
          }
        }

        const timeOutId = setTimeout(() => setSKUs(tempSku), 1000);
        resetInputField();
        setState((prevState) => ({
          ...prevState,
          inputFieldError: "",
        }));
        return () => clearTimeout(timeOutId);
      } else {
        setState((prevState) => ({
          ...prevState,
          inputFieldError: "Please enter a valid OrderId",
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        inputFieldError: `${response?.data?.message}`,
      }));
    }

    setLoading(false);
  }

  const handleUserInput = (e) => {
    setState((prevState) => ({
      ...prevState,
      inputFiledValue: e.target.value,
      inputFieldError: "",
    }));
    clearMissingSkuMessage();
  };

  const handleSkuSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (state?.isUsingOrderId) {
      let order_id = state.inputFiledValue;
      if (order_id !== "") {
        const timeOutId = setTimeout(
          () => getProductUsingOrderId(order_id),
          1000
        );
        return () => clearTimeout(timeOutId);
      } else {
        setState((prevState) => ({
          ...prevState,
          inputFieldError: "",
        }));
      }
    } else {
      let sku = state.inputFiledValue;
      if (sku !== "") {
        const timeOutId = setTimeout(() => getSingleProduct(sku), 1000);
        return () => clearTimeout(timeOutId);
      } else {
        setState((prevState) => ({
          ...prevState,
          inputFieldError: "",
        }));
      }
    }
  };

  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      handleSkuSubmit(e);
    }
  };

  const getCustomerOrders = async () => {
    const response = await Api.get(customerOrdersURL);
    if (response.status === 200) {
      let tempSku = [];
      const res = response?.data?.data;
      if (res?.length > 0) {
        for (const r of res) {
          if (r.customer_id !== 0 && r.orderid !== 0) {
            tempSku.push(r.orderid);
          }
        }
        const firstTwoValues = tempSku.slice(0, 2);
        const commaSeparatedString = firstTwoValues.join(", ");
        setState((prevState) => ({
          ...prevState,
          orderIds: commaSeparatedString,
        }));
      }
    }
  };

  const resetInputField = () => {
    setState((prevState) => ({
      ...prevState,
      inputFiledValue: "",
    }));
  };
  const resetInputfile = () => {
    aRef.current.value = null;
    setState((prevState) => ({ ...prevState, file: "" }));
  };

  const clearMissingSkuMessage = () => {
    setState((prevState) => ({
      ...prevState,
      missing_skus_string: "",
    }));
  };

  const clearCartForm = async (isAddToCart) => {
    const res = JSON.parse(window.localStorage.getItem("cartFormItems"));
    let removeFlag = false;
    if (res?.length) {
      removeFlag = true;
    }
    setState((prevState) => ({
      ...prevState,
      lineItems: [],
      cartTotal: "",
      missing_skus_string: "",
    }));
    window.localStorage.setItem("cartTotal", "");
    window.localStorage.setItem("itemErrorString", "");
    window.localStorage.setItem("itemError", 0);
    window.localStorage.setItem("cartFormItems", JSON.stringify([]));
    window.localStorage.setItem("duplicateUpcItems", JSON.stringify([]));
    reset();
    if (isAddToCart) {
      // mutate("/cart");
      // // toastMessage("success", "Items Added to the cart successfully.");
      // // window.location.reload();
      // navigate("/cart");
    } else {
      if (removeFlag) {
        toastMessage("success", "Cart form reset successfully.");
      }
    }
  };

  const onClickClearCartForm = async () => {
    await clearCartForm(false);
    loadCartData();
  };

  useEffect(async () => {
    setLoading(true);
    if (skus && user) {
      const response = await Api.post(cartFormItemsURL, skus);

      if (response?.status === 200) {
        const missing_skus_data = response?.data?.missing_skus;
        if (response?.data?.data?.length > 0) {
          //  -----------------------------------------------------------------------

          const storedItems =
            JSON.parse(window.localStorage.getItem("cartFormItems")) || [];
          // console.log(storedItem, "storebef");
          // if (existingItems.length > 0) {
          //   window.localStorage.setItem(
          //     "cartFormItems",
          //     JSON.stringify(existingItems)
          //   );
          // }
          // const storedItems = JSON.parse(
          //   window.localStorage.getItem("cartFormItems")
          // );
          // console.log(storedItems, "storeaft");
          const newItems = response.data.data;
          // console.log(newItems, "new");
          newItems.forEach((newItem) => {
            const existingItemIndex = storedItems.findIndex(
              (item) => item.sku === newItem.sku
            );
            if (existingItemIndex !== -1) {
              storedItems[existingItemIndex].quantity = newItem.quantity;
              storedItems[existingItemIndex].extended_price =
                storedItems[existingItemIndex].quantity *
                (storedItems[existingItemIndex].price ||
                  storedItems[existingItemIndex].calculated_price);
            } else {
              storedItems.push(newItem);
            }
            // console.log(storedItems, "items");
          });
          window.localStorage.setItem(
            "cartFormItems",
            JSON.stringify(storedItems)
          );
          // ------------------------------------------------------------------
          // const res = JSON.parse(window.localStorage.getItem("cartFormItems"));
          // const cartList = existingItems;
          // res.forEach((resItem) => {
          //   const cartItem = cartList.find(
          //     (cartItem) => cartItem.sku === resItem.sku
          //   );
          //   if (cartItem) {
          //     cartItem.quantity = resItem.quantity;
          //     cartItem.extended_price = resItem.extended_price;
          //   } else {
          //     cartList.push(resItem);
          //   }
          // });
          if (!isUsingInput) {
            // await clearCartForm(false);
            setState((prevState) => ({
              ...prevState,
              lineItems: storedItems,
              cartTotal: calculateSum(storedItems),
            }));
          }
          if (state.isDuplicateUpc) {
            setState((prevState) => ({
              ...prevState,
              duplicateUpcItems: response?.data?.data,
            }));
            window.localStorage.setItem(
              "duplicateUpcItems",
              JSON.stringify(response?.data?.data)
            );
          } else {
            setLineItemsInCart(storedItems);
          }
        } else {
          window.localStorage.setItem("itemError", 1);
          setState((prevState) => ({
            ...prevState,
            error: "looks like the products you are looking for has not found",
          }));
          window.localStorage.setItem("itemErrorString", "No products found.");
        }
        if (missing_skus_data?.length) {
          const commaSeparatedString = missing_skus_data.join(", ");

          // window.localStorage.setItem(
          //   "missing_skus_string",
          //   commaSeparatedString.toString()
          // );
          setState((prevState) => ({
            ...prevState,
            missing_skus_string: commaSeparatedString,
          }));
        }
      } else {
        window.localStorage.setItem("itemError", 1);
        setState((prevState) => ({
          ...prevState,
          error: "some thing went wrong",
        }));
      }
    }
    setLoading(false);
  }, [skus]);

  useEffect(() => {
    const res = JSON.parse(window.localStorage.getItem("cartFormItems"));

    const sum = window.localStorage.getItem("cartTotal");
    setState((prevState) => ({
      ...prevState,
      ...(res && { lineItems: res }),
      ...(sum && { cartTotal: sum }),
    }));
  }, []);

  useEffect(() => {}, [cartResponse]);

  const setLineItemsInCart = (res) => {
    // let res = JSON.parse(window.localStorage.getItem("cartFormItems"));
    if (res && res?.length > 0) {
      if (isUsingInput) {
        // let appendFlag = true;
        // data.forEach((child) => {
        //   let appendFlag = true;
        //   res.forEach((i) => {
        //     if (
        //       i["sku"] === child["sku"] &&
        //       i["variant_id"] === child["variant_id"]
        //     ) {
        //       i["quantity"] = i["quantity"] + child["quantity"];
        //       i["extended_price"] =
        //         i["quantity"] * (i["price"] || i["calculated_price"]);
        //       appendFlag = false;
        //     }
        //   });
        //   if (appendFlag) {
        //     res = [...res, child];
        //   }
        // });
        const sortedLineItems = sortLineItemArray(res);
        setState((prevState) => ({
          ...prevState,
          lineItems: sortedLineItems,
          cartTotal: calculateSum(sortedLineItems),
        }));
        checkForItemError(sortedLineItems);
        window.localStorage.setItem(
          "cartFormItems",
          JSON.stringify(sortedLineItems)
        );
        setUsingInput(false);
      } else {
        const sortedLineItems = sortLineItemArray(res);
        setState((prevState) => ({
          ...prevState,
          lineItems: sortedLineItems,
          cartTotal: calculateSum(sortedLineItems),
        }));
        checkForItemError(sortedLineItems);
        window.localStorage.setItem(
          "cartFormItems",
          JSON.stringify(sortedLineItems)
        );
        // setUsingInput(false);
      }
    } else {
      // const sortedLineItems = sortLineItemArray(res);
      // setState((prevState) => ({
      //   ...prevState,
      //   lineItems: sortedLineItems,
      //   cartTotal: calculateSum(sortedLineItems),
      // }));
      // checkForItemError(sortedLineItems);
      // window.localStorage.setItem(
      //   "cartFormItems",
      //   JSON.stringify(sortedLineItems)
      // );
    }
    toastMessage("success", "Product added successully.");
  };

  const sortLineItemArray = (data) => {
    const itemsWithErrors = [];
    const itemsWithoutErrors = [];
    data.forEach((child) => {
      if (
        child["inventory_level"] === 0 ||
        (child["quantity"] < child["minPurchaseQuantity"] &&
          child["minPurchaseQuantity"] !== null) ||
        (child["quantity"] > child["maxPurchaseQuantity"] &&
          child["maxPurchaseQuantity"] !== null) ||
        child["inventory_level"] < child["quantity"]
      ) {
        itemsWithErrors.push(child);
      } else {
        itemsWithoutErrors.push(child);
      }
    });

    const sortedLineItems = [...itemsWithErrors, ...itemsWithoutErrors];
    return sortedLineItems;
  };

  const handleFileChange = (e) => {
    setUsingInput(false);
    clearMissingSkuMessage();
    resetInputField();
    setState((prevState) => ({
      ...prevState,
      fileError: "",
      inputFieldError: "",
    }));

    if (e.target.files.length) {
      const inputFile = e.target.files[0];
      // const fileExtension = inputFile?.type.split("/")[1];
      const fileExtension = inputFile?.name.split(".").pop().toLowerCase();
      const fileSize = inputFile.size / Math.pow(1024, 2);

      if (!allowedExtensions.includes(fileExtension)) {
        setState((prevState) => ({
          ...prevState,
          fileError: "Please input a XLSX or XLS file",
        }));
        return;
      }

      if (fileSize > 10) {
        setState((prevState) => ({
          ...prevState,
          fileError: "File is more than 10MB",
        }));
        return;
      }
      setState((prevState) => ({ ...prevState, file: inputFile }));
    }
  };

  const handleParse = () => {
    // window.localStorage.setItem("cartFormItems", JSON.stringify([]));
    if (!state.file) {
      return setState((prevState) => ({
        ...prevState,
        fileError: "Enter a valid file",
      }));
    }

    const reader = new FileReader();
    reader.readAsBinaryString(state.file);
    reader.onload = async (e) => {
      const binaryString = e?.target?.result;
      const workbook = XLSX?.read(binaryString, { type: "binary" });
      if (!workbook) {
        return setState((prevState) => ({
          ...prevState,
          fileError: "Workbook not parsed correctly",
        }));
      }
      const firstSheetName = workbook?.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      let tempSku = [];
      let firstIndex = true;

      let nonEmptyRowCount = 0;

      for (const d of excelData) {
        if (!d.every((cell) => cell === "")) {
          nonEmptyRowCount++;
          if (Object.values(d)[0] !== "") {
            if (firstIndex) {
              if (
                Object.values(d)[0] === undefined ||
                Object.values(d)[1] === undefined
              ) {
                setState((prevState) => ({
                  ...prevState,
                  fileError: "Please enter a valid file.",
                }));
                return;
              } else {
                firstIndex = false;
              }
            } else {
              const sku = parseFloat(Object.values(d)[0]); // Convert SKU to a number
              tempSku.push({
                sku: isNaN(sku)
                  ? Object.values(d)[0].toString()
                  : sku.toString(),
                quantity: parseInt(Object.values(d)[1]),
              });
            }
          }
        }
      }
      if (nonEmptyRowCount > 250) {
        // Check if non-empty row count exceeds 250
        setState((prevState) => ({
          ...prevState,
          fileError: "Excel file should not contain more than 250 records.",
        }));
        return;
      }
      if (state.fileError === "") {
        const timeOutId = setTimeout(() => setSKUs(tempSku), 1000);
        return () => clearTimeout(timeOutId);
      }
    };
    // reader.readAsText(state.file);
    setTimeout(() => resetInputfile(), 2000);
  };

  const removeItem = async (variant_id) => {
    const res = JSON.parse(window.localStorage.getItem("cartFormItems"));
    if (res) {
      const filteredArr = res.filter((i) => i["variant_id"] !== variant_id);
      setState((prevState) => ({
        ...prevState,
        lineItems: filteredArr,
        cartTotal: calculateSum(filteredArr),
      }));
      checkForItemError(filteredArr);
      if(filteredArr.length){
                window.localStorage.setItem("cartFormItems", JSON.stringify(filteredArr));
      }
    }
    return res;
  };

  const removeSelectedItems = async () => {
    let selectedSkus = watch("skus", []);

    // Ensure selectedSkus is always an array
    if (!Array.isArray(selectedSkus)) {
      selectedSkus = [selectedSkus];
    }

    if (selectedSkus.length > 0) {
      for (const ID of selectedSkus) {
        await removeItem(parseInt(ID));
      }
      let itemErrorFlag =
        isBrowser && parseInt(window.localStorage.getItem("itemError"));
      if (!itemErrorFlag) {
        toastMessage("success", "Product(s) removed successfully.");
      }
    }
    reset();
  };

  const handleQuantityChange = async (
    value,
    sku,
    toastMessage,
    formik,
    flag
  ) => {
    const res = JSON.parse(window.localStorage.getItem("cartFormItems"));
    res.forEach((i) => {
      if (i["sku"] === sku) {
        let inputValidation = false;
        let qnt = 0;
        if (flag) {
          qnt = value;
          if (value > i["quantity"]) {
            value = 1;
          } else {
            value = -1;
          }
        } else {
          qnt = i["quantity"] + value;
        }

        inputValidation = scannerValidateInputBox(
          qnt,
          i["minPurchaseQuantity"] || 0,
          i["maxPurchaseQuantity"] || i["inventory_level"],
          i["inventory_level"],
          toastMessage,
          `${i["id"]},${i["sku"]},${
            i["price"] ? i["price"].toString().replace(".", "-") : ""
          }`,
          formik,
          value
        );
        const minQtyCheck = i["minPurchaseQuantity"] || 0;
        if (value === -1 && !inputValidation && qnt > minQtyCheck) {
          inputValidation = true;
        }

        if (inputValidation) {
          i["quantity"] = qnt;
          i["extended_price"] = qnt * (i["price"] || i["calculated_price"]);
          formik.setFieldValue("quantity", qnt);
        } else {
          formik.setFieldValue("quantity", i["quantity"]);
        }
        checkForItemError(res);
      }
    });

    setState((prevState) => ({
      ...prevState,
      lineItems: res,
      cartTotal: calculateSum(res),
    }));
    localStorage.setItem("cartFormItems", JSON.stringify(res));
  };

  const onTriggerAddToCart = async () => {
    setLoading(true);
    const res = JSON.parse(window.localStorage.getItem("cartFormItems"));
    if (res.length) {
      res.forEach((i) => {
        if (i["inventory_level"] < i["quantity"]) {
          i["quantity"] = i["inventory_level"];
        }
      });
    }

    await clearCart();
    const response = await addToCart(res, () => {}, setCartResponse);

    if (response?.line_items?.length > 0) {
      // clearCartForm(true);
      // navigate("/cart");
      mutate("/cart");
      navigate("/cart");
      if (window.location.pathname.includes("/cart")) {
        // Reload the page
        window.location.reload();
      }
    } else if (response?.message) {
      toastMessage("error", "For " + response?.message);
    }
    setTimeout(() => setLoading(false), 1500);
  };

  const onCloseDuplicateUpcModal = () => {
    setState((prevState) => ({
      ...prevState,
      isDuplicateUpc: false,
      duplicateUpcItems: [],
    }));
    window.localStorage.setItem("duplicateUpcItems", JSON.stringify([]));
  };

  const onChooseSku = (id) => {
    const res = JSON.parse(window.localStorage.getItem("duplicateUpcItems"));
    let filteredData = [];
    if (Array.isArray(res) && res.length) {
      filteredData = res.filter((item) => item.variant_id === parseInt(id));
    }
    if (filteredData.length) {
      let tempSku = [];
      filteredData.forEach((r) => {
        if (r.product_id !== 0 && r.variant_id !== 0) {
          tempSku.push({
            sku: r.sku,
            quantity: parseInt(1),
          });
          const timeOutId = setTimeout(() => setSKUs(tempSku), 1000);
          return () => clearTimeout(timeOutId);
        }
      });
    }
    onCloseDuplicateUpcModal();
  };

  const downloadSampleExcel = async () => {
    setLoading(true);

    const getSingleKey = () => `${sampleCsvURL}`;
    const response = await Api.get(getSingleKey());
    if (response?.status === 200) {
      const ws = XLSX.utils.json_to_sheet(response?.data);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      // Generate XLSX content
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      saveAs(blob, `sample-${getCurrentDate()}.xlsx`);
      // const url = URL.createObjectURL(blob);
      // const link = document.createElement("a");

      // link.href = url;
      // link.setAttribute("download", `sample-${getCurrentDate()}.xlsx`);

      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    } else {
      toastMessage("error", response?.message);
    }

    //  setLoading(false)
  };
  return {
    ...state,
    isLoading,
    isUsingInput,
    existingItems,
    isLoadingCart,
    setState,
    handleFileChange,
    handleParse,
    removeItem,
    handleQuantityChange,
    onTriggerAddToCart,
    clearCartForm,
    register,
    removeSelectedItems,
    handleUserInput,
    handleEnterKey,
    handleSkuSubmit,
    aRef,
    onCloseDuplicateUpcModal,
    onChooseSku,
    handleInputTypeChange,
    clearMissingSkuMessage,
    downloadSampleExcel,
    loadCartData,
    onClickClearCartForm,
  };
}

export default useCartForm;
