import React, { useState } from "react";
import defaultImage from "../../../static/img/default-bc-product.png";
import Button from "../form/button/Button";
// import useGetOrderDetails, {
//   DataToOrderInfo,
//   DataToOrderProducts,
//   DataToOrderShippingAddress,
// } from "../../hooks/account-orders/useGetOrderDetail";
import PropTypes from "prop-types";
import Image from "../common/Image";
import { navigate } from "gatsby";
import useGetOrderDetails from "../../hooks/account-orders/useGetOrderDetails";
import { formatPrice } from "../../utils/money";
import { formatDate, DATE_FORMAT_PRIMARY } from "../../utils/date";
import useCreateReorder from "../../hooks/cart/useCreateReorder";
import Breadcrumbs from "../common/breadcrumbs/Breadcrumbs";
import AccountPageNavigation from "../account/AccountPageNavigation";
import useGetRepresentativesDetails from "../../hooks/representatives-details/useGetRepresentativesDetails";
import RepresentativeNavigation from "../account/RepresentativeNavigation";
import { useLocation } from "@reach/router";
import { isEmpty } from "../../utils/functions";
import ButtonLoader from "../form/ButtonLoader";
import { isOrderReturnable } from "../../utils/account";

const OrderProductDetails = ({ orderId }) => {
  const location = useLocation();
  const [reorderProduct, setReorderProducts] = useState([]);
  const { isLoading, orderInfo, orderProducts, orderShippingAddress } =
    useGetOrderDetails(orderId);
  const { reorderProducts, isLoading: submittingReorder } = useCreateReorder();
  const { details: representative } = useGetRepresentativesDetails();

  const handleReorderProductSelection = (sku, isChecked) => {
    // find sku in orderProducts and create below kind of schema
    const selectedProduct = orderProducts.reduce((acc, item) => {
      if (item.sku === sku) {
        acc.push({
          product_id: item.product_id,
          options: item.product_options
            ? item.product_options.reduce((acc, opt) => {
                acc[opt.display_name] = opt.display_value;
                return acc;
              }, {})
            : {},
          sku: item.sku,
          qty: item.quantity,
        });
      }

      return acc;
    }, []);

    if (isChecked) {
      setReorderProducts([...reorderProduct, ...selectedProduct]);
    } else {
      setReorderProducts(reorderProduct.filter((item) => item.sku !== sku));
    }
  };

  const handleReorderSubmitAction = async (items) => {
    await reorderProducts(items);
  };

  const handleSelectAll = () => {
    // If "Select All" is checked, select all items
    if (orderProducts.length === reorderProduct.length) {
      setReorderProducts([]);
    } else {
      const allProducts = orderProducts.map((item) => ({
        product_id: item.product_id,
        options: item.product_options
          ? item.product_options.reduce((acc, opt) => {
              acc[opt.display_name] = opt.display_value;
              return acc;
            }, {})
          : {},
        sku: item.sku,
        qty: item.quantity,
      }));
      setReorderProducts([...allProducts]);
    }
  };

  const handlePrintInvoice = () => {
    window.open(`/invoice/?id=${orderId}`, "_blank");
  };

  return (
    !isLoading && (
      <>
        <div className="page-wrapper account-page-wrapper">
          <div className="container">
            <Breadcrumbs location={location} />
          </div>

          <div className="container">
            <div className="account-content">
              <div className="row flex flex-wrap page-layout">
                <div className="col page-sidebar">
                  <div className="page-sidebar-inner">
                    <AccountPageNavigation path={"/orders"} />
                    {representative && (
                      <RepresentativeNavigation
                        representative={representative}
                      />
                    )}
                  </div>
                </div>
                <div className="col page-content">
                  <div className="page-content-inner">
                    <div className="order-details-container">
                      <div className="row flex flex-wrap">
                        <div className="col account-page-content">
                          <div className="page-heading-section order-tracking-heading">
                            <h1 className="page-title">{`Order - #${orderId}`}</h1>
                          </div>

                          <div className="all-order-selecction">
                            <input
                              id="select_all"
                              className="select-all-checkbox form-checkbox"
                              type="checkbox"
                              onClick={handleSelectAll}
                            />
                            <label className="form-label" htmlFor="select_all">
                              Select all Orders
                            </label>
                          </div>

                          <ul className="order-list">
                            {orderProducts.map((item, index) => (
                              <li
                                className="account-listItem"
                                key={item.orderId}
                              >
                                <div className="row flex vertical-top">
                                  <div className="col order-item-select">
                                    <input
                                      type="checkbox"
                                      value={item.product_id}
                                      id={index}
                                      className="form-checkbox"
                                      onClick={(e) => {
                                        handleReorderProductSelection(
                                          item.sku,
                                          e.target.checked
                                        );
                                      }}
                                      checked={reorderProduct.some(
                                        (product) => product.sku === item.sku
                                      )}
                                    />
                                    <label
                                      className="form-label"
                                      htmlFor={index}
                                    >
                                      &nbsp;
                                    </label>
                                  </div>
                                  <div className="col account-product-figure">
                                    <Image
                                      src={item.images || defaultImage}
                                      alt="OrderDetail"
                                    />
                                  </div>
                                  <div className="col account-product-body">
                                    <div className="account-right-tag order-detail-price">
                                      <div className="price-wrapper">
                                        <div className="customer-badge-id-overlay">
                                          <div>{orderInfo?.customer_id}</div>
                                        </div>
                                        <div className="product-price-wrapper">
                                          <p className="bc-product__pricing--api bc-product__pricing--visible product-price-item">
                                            <span className="original-price-node bc-product__original-price bc-show-current-price current-price">
                                              <span>{` ${formatPrice(
                                                item.total_inc_tax.toString()
                                              )}`}</span>
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <h5 className="account-product-title">
                                      {`${item.quantity} x ${item.name}`}
                                    </h5>
                                    <dl className="definitionList">
                                      {item.product_options?.map(
                                        (opt, index) => (
                                          <>
                                            <dt className="definitionList-key">
                                              {opt.display_name}:
                                            </dt>
                                            <dd className="definitionList-value">
                                              {opt.display_value}
                                            </dd>
                                          </>
                                        )
                                      )}
                                    </dl>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>

                          <div className="order-detail-total-section">
                            <div className="flex justify-space order-total-item">
                              <span>SubTotal:</span>
                              <strong>
                                {formatPrice(orderInfo.sub_total_ex_tax)}
                              </strong>
                            </div>
                            <div className="flex justify-space order-total-item">
                              <span>Shipping:</span>
                              <strong>
                                {formatPrice(orderInfo.shipping_cost_inc_tax)}
                              </strong>
                            </div>
                            <div className="flex justify-space order-total-item">
                              <span>Tax:</span>
                              <strong>
                                {formatPrice(orderInfo.subtotal_tax)}
                              </strong>
                            </div>
                            <div className="flex justify-space order-total-item">
                              <span>Grand total:</span>
                              <strong>
                                <big>
                                  {formatPrice(orderInfo.total_inc_tax)}
                                </big>
                              </strong>
                            </div>
                          </div>
                        </div>

                        <div className="col account-page-sidebar">
                          <div className="account-sidebar-block">
                            <h3 className="account-heading">Order Details</h3>
                            <dl className="definitionList">
                              <dt>Order status:</dt>
                              <dd>{orderInfo.order_status}</dd>
                              <dt>Order date:</dt>
                              <dd>
                                {formatDate(
                                  orderInfo.order_date,
                                  DATE_FORMAT_PRIMARY
                                )}
                              </dd>
                              <dt>Order total:</dt>
                              <dd>{formatPrice(orderInfo.total_ex_tax)}</dd>
                              <dt>Payment method:</dt>
                              <dd>{orderInfo.payment_method}</dd>
                            </dl>
                            <Button
                              type={"submit"}
                              size={"small"}
                              variant={"secondary"}
                              onClick={handlePrintInvoice}
                            >
                              Print Invoice
                            </Button>
                          </div>

                          <div className="account-sidebar-block">
                            <h3 className="account-heading">Ship to</h3>
                            <ul className="sidebar-address">
                              {!isEmpty(orderShippingAddress) && (
                                <>
                                  <li>{`${orderShippingAddress[0].first_name} ${orderShippingAddress[0].last_name}`}</li>
                                  <li>{orderShippingAddress[0].company}</li>
                                  <li>{orderShippingAddress[0].street_1}</li>
                                  <li>{orderShippingAddress[0].street_2}</li>
                                  <li>{`${orderShippingAddress[0].city}, ${orderShippingAddress[0].state} ${orderShippingAddress[0].zip}`}</li>
                                  <li>{orderShippingAddress[0].country}</li>
                                </>
                              )}
                            </ul>
                          </div>

                          <div className="account-sidebar-block">
                            <h3 className="account-heading">Bill to</h3>
                            <ul className="sidebar-address">
                              <li>{orderInfo.billing_address.first_name}</li>
                              <li>{orderInfo.billing_address.company}</li>
                              <li>{orderInfo.billing_address.street_1}</li>
                              <li>{orderInfo.billing_address.street_2}</li>
                              <li>{`${orderInfo.billing_address.city}, ${orderInfo.billing_address.state} ${orderInfo.billing_address.zip}`}</li>
                              <li>{orderInfo.billing_address.country}</li>
                            </ul>
                          </div>

                          {orderInfo.customer_message && (
                            <div className="account-sidebar-block">
                              <h3 className="account-heading">
                                Order Comments
                              </h3>
                              <ul className="sidebar-address">
                                <li>{orderInfo.customer_message}</li>
                              </ul>
                            </div>
                          )}

                          <div className="account-sidebar-block">
                            <h3 className="account-heading">Actions</h3>
                            {submittingReorder ? (
                              <ButtonLoader />
                            ) : (
                              <>
                                <Button
                                  type={"submit"}
                                  size={"small"}
                                  variant={"secondary"}
                                  disabled={
                                    !reorderProduct.length ? true : false
                                  }
                                  onClick={() => {
                                    handleReorderSubmitAction(reorderProduct);
                                  }}
                                >
                                  Reorder
                                </Button>
                                {isOrderReturnable(orderInfo?.order_status) ? (
                                  <Button
                                    size={"small"}
                                    variant={"secondary"}
                                    onClick={() => {
                                      navigate(
                                        `/returns?order_id=${orderInfo.order_id}`,
                                        {
                                          state: {
                                            orderID: orderInfo.order_id,
                                          },
                                        }
                                      );
                                    }}
                                    style={{ marginLeft: "10px" }}
                                  >
                                    Return
                                  </Button>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

OrderProductDetails.propTypes = {
  orderId: PropTypes.number,
};

export default OrderProductDetails;
